import { render, staticRenderFns } from "./BukuPembantu.vue?vue&type=template&id=56846b70&scoped=true&"
import script from "./BukuPembantu.vue?vue&type=script&lang=js&"
export * from "./BukuPembantu.vue?vue&type=script&lang=js&"
import style0 from "./BukuPembantu.vue?vue&type=style&index=0&id=56846b70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56846b70",
  null
  
)

export default component.exports